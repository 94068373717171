var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [!_vm.isInvoiceAvailable ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih No. Invoice")]), _c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "options": _vm.invoiceList,
      "label": "text"
    },
    model: {
      value: _vm.selectedInvoice,
      callback: function ($$v) {
        _vm.selectedInvoice = $$v;
      },
      expression: "selectedInvoice"
    }
  })], 1) : _c('p', {
    staticClass: "mb-0"
  }, [_c('strong', [_vm._v("ID " + _vm._s(_vm.pb.id) + " No. Invoice: " + _vm._s(_vm.pb.no_invoice))])])], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "d-flex justify-content-between"
  }, [_c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.right",
      value: 'Tambah',
      expression: "'Tambah'",
      modifiers: {
        "hover": true,
        "right": true
      }
    }],
    staticClass: "mt-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push("/penyimpanan/add");
      }
    }
  }, [_c('span', {
    staticClass: "align-middle"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Penyimpanan Barang")], 1)])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-card', [_c('b-table', {
    attrs: {
      "responsive": "",
      "striped": "",
      "bordered": "",
      "fields": _vm.fields,
      "items": _vm.barangs
    },
    scopedSlots: _vm._u([{
      key: "cell(action)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-radio', {
          attrs: {
            "value": item,
            "name": "barang"
          },
          model: {
            value: _vm.selectedBarang,
            callback: function ($$v) {
              _vm.selectedBarang = $$v;
            },
            expression: "selectedBarang"
          }
        })];
      }
    }, {
      key: "cell(barang)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : "-") + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.barang && item.barang.satuan ? item.barang.satuan.satuan : "-") + " ")];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-card', [_c('form', [!_vm.isInvoiceAvailable ? _c('b-form-group', {
    attrs: {
      "hidden": ""
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "penerimaan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Penerimaan")]), _c('v-select', {
          attrs: {
            "options": _vm.invoiceList,
            "required": !_vm.form.penerimaan_id,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.penerimaan_id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "penerimaan_id", $$v);
            },
            expression: "form.penerimaan_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 669795941)
  })], 1) : _vm._e(), _c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "gudang",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var errors = _ref5.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Gudang")]), _c('v-select', {
          attrs: {
            "options": _vm.gudangs,
            "required": !_vm.form.gudang_id,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.gudang_id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "gudang_id", $$v);
            },
            expression: "form.gudang_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "blok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Blok")]), _c('v-select', {
          attrs: {
            "options": _vm.bloks,
            "required": !_vm.form.blok_id,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.blok_id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "blok_id", $$v);
            },
            expression: "form.blok_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Pallet")]), _c('v-select', {
    attrs: {
      "options": _vm.palets,
      "required": !_vm.form.palet_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.palet_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "palet_id", $$v);
      },
      expression: "form.palet_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Rak (optional)")]), _c('v-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.raks,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.rak_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rak_id", $$v);
      },
      expression: "form.rak_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Laci (optional)")]), _c('v-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.lacis,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.laci_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "laci_id", $$v);
      },
      expression: "form.laci_id"
    }
  })], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nilai"
    }
  }, [_vm._v("Jumlah")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "id": "nilai",
      "placeholder": "nilai"
    },
    model: {
      value: _vm.nilai,
      callback: function ($$v) {
        _vm.nilai = $$v;
      },
      expression: "nilai"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "konversi"
    }
  }, [_vm._v("Konversi")]), _c('b-form-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "options": _vm.optkonversi,
      "id": "konversi",
      "placeholder": "konversi"
    },
    model: {
      value: _vm.id_satuan,
      callback: function ($$v) {
        _vm.id_satuan = $$v;
      },
      expression: "id_satuan"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "jumlah"
    }
  }, [_vm._v("Jumlah Hasil Konversi")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "id": "jumlah",
      "placeholder": "Jumlah"
    },
    model: {
      value: _vm.form.jumlah,
      callback: function ($$v) {
        _vm.$set(_vm.form, "jumlah", $$v);
      },
      expression: "form.jumlah"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  })], 1), _c('b-form-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addBarang($event);
      }
    }
  }, [_vm._v("Tambah")])], 1)], 1)])], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-table', {
    attrs: {
      "fields": _vm.fieldsAddedItems,
      "bordered": "",
      "striped": "",
      "responsive": "",
      "items": _vm.addedItems
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(lokasi)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.lokasi) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function (row) {
        return [_c('b-link', {
          staticClass: "text-danger",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.addedItems.splice(row.index, 1);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "24",
            "icon": "XCircleIcon"
          }
        })], 1)];
      }
    }])
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }