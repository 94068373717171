<template>
  <main>
    <b-overlay :show="loading">
      <validation-observer ref="form">
        <b-card>
            <b-row>
          <b-col sm="12" md="6">
            <b-form-group v-if="!isInvoiceAvailable">
              <label for="">Pilih No. Invoice</label>
              <v-select
                v-model="selectedInvoice"
                :reduce="(option) => option.value"
                :options="invoiceList"
                label="text"
              />
            </b-form-group>
            <p class="mb-0" v-else>
              <strong>ID {{ pb.id }} No. Invoice: {{ pb.no_invoice }}</strong>
            </p>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group class="d-flex justify-content-between">
              <b-button
                v-b-tooltip.hover.right="'Tambah'"
                class="mt-2"
                @click.prevent="$router.push(`/penyimpanan/add`)"
                variant="outline-primary"
              >
              <span class="align-middle"><feather-icon icon="PlusIcon" /> Penyimpanan Barang</span>
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        </b-card>
        <!-- two card -->
        <b-row>
          <!-- List barang di penerimaan -->
          <b-col sm="12" md="6">
            <b-card>
              <b-table
                responsive
                striped
                bordered
                :fields="fields"
                :items="barangs"
              >
                <template #cell(action)="{ item }">
                  <b-form-radio
                    v-model="selectedBarang"
                    :value="item"
                    :name="`barang`"
                  />
                </template>
                <template #cell(barang)="{ item }">
                  {{ item.barang ? item.barang.nama : "-" }}
                </template>
                <template #cell(satuan)="{ item }">
                  {{
                    item.barang && item.barang.satuan
                      ? item.barang.satuan.satuan
                      : "-"
                  }}
                </template>
              </b-table>
            </b-card>
          </b-col>
          <!-- Penempatan gudang -->
          <b-col sm="12" md="6">
            <b-card>
              <form>
                <b-form-group v-if="!isInvoiceAvailable" hidden>
                  <validation-provider
                    #default="{ errors }"
                    name="penerimaan"
                    rules="required"
                  >
                    <label for="">Pilih Penerimaan</label>
                    <v-select
                      :options="invoiceList"
                      v-model="form.penerimaan_id"
                      :required="!form.penerimaan_id"
                      :reduce="(option) => option.value"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="gudang"
                    rules="required"
                  >
                    <label for="">Pilih Gudang</label>
                    <v-select
                      :options="gudangs"
                      v-model="form.gudang_id"
                      :required="!form.gudang_id"
                      :reduce="(option) => option.value"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="blok"
                    rules="required"
                  >
                    <label for="">Pilih Blok</label>
                    <v-select
                      :options="bloks"
                      v-model="form.blok_id"
                      :required="!form.blok_id"
                      :reduce="(option) => option.value"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <label for="">Pilih Pallet</label>
                  <v-select
                    :options="palets"
                    v-model="form.palet_id"
                    :required="!form.palet_id"
                    :reduce="(option) => option.value"
                    label="text"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="">Pilih Rak (optional)</label>
                  <v-select
                    v-model="form.rak_id"
                    :options="raks"
                    :reduce="(option) => option.value"
                    label="text"
                    class="mb-2"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="">Pilih Laci (optional)</label>
                  <v-select
                    v-model="form.laci_id"
                    :options="lacis"
                    :reduce="(option) => option.value"
                    label="text"
                    class="mb-2"
                  />
                </b-form-group>
                <!-- <b-form-group >
                                    <validation-provider 
                                        #default="{errors}"
                                        name="jumlah"
                                        rules="required|positive"
                                    >
                                        <label for="">Jumlah</label>
                                        <b-form-input type="number" v-model="form.jumlah" class="mb-2" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group> -->
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <label for="nilai">Jumlah</label>
                      <b-form-input
                        v-model="nilai"
                        type="number"
                        id="nilai"
                        placeholder="nilai"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="konversi">Konversi</label>
                      <b-form-select
                        v-model="id_satuan"
                        :reduce="(option) => option.value"
                        :options="optkonversi"
                        id="konversi"
                        placeholder="konversi"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <label for="jumlah">Jumlah Hasil Konversi</label>
                      <b-form-input
                        v-model="form.jumlah"
                        type="number"
                        id="jumlah"
                        placeholder="Jumlah"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <!-- <b-form-group>
                      <label for="satuan">Satuan</label>
                      <b-form-input
                        v-model="satuan"
                        :value="selectedBarang.barang.satuan.satuan"
                        id="satuan"
                        placeholder="satuan"
                        disabled
                      />
                    </b-form-group> -->
                  </b-col>
                </b-row>

                <b-form-group>
                  <b-button @click.prevent="addBarang" variant="primary"
                    >Tambah</b-button
                  >
                </b-form-group>
              </form>
            </b-card>
          </b-col>
          <!-- Barang yang sudah disimpan -->
          <b-col cols="12">
            <b-card>
              <b-table
                :fields="fieldsAddedItems"
                bordered
                striped
                responsive
                :items="addedItems"
              >
                <template #cell(no)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(lokasi)="{ item }">
                  <strong>
                    {{ item.lokasi }}
                  </strong>
                </template>
                <template #cell(action)="row">
                  <b-link
                    class="text-danger"
                    @click.prevent="addedItems.splice(row.index, 1)"
                  >
                    <feather-icon size="24" icon="XCircleIcon"></feather-icon>
                  </b-link>
                </template>
              </b-table>
              <div class="d-flex justify-content-end">
                <b-button variant="primary" @click.prevent="submit">
                  Simpan
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </main>
</template>
<script>
import {
  BTable,
  BOverlay,
  BFormSelect,
  BFormRadio,
  BLink,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { extend } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "This field is required",
});
extend("positive", (value) => {
  return value > 0;
});

export default {
  components: {
    vSelect,
    BFormSelect,
    BLink,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    BFormGroup,
    BFormRadio,
    BTable,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BButton,
    BFormInput,
  },
  watch: {
    "selectedBarang.barang.id"(barang_id) {
      this.getKonversi(barang_id);
    },
    "selectedBarang.barang.id"(barang_id) {
      this.getKonversi(barang_id);
    },
    nilai(value2) {
      let hasil = value2 * this.id_satuan;
      this.form.jumlah = hasil;
    },
    id_satuan(value) {
      let hasil = value * this.nilai;
      this.form.jumlah = hasil;
    },
    "form.gudang_id"(gudang_id) {
      this.getBlok(gudang_id);
    },
    "form.blok_id"(blok_id) {
      this.getpalet(blok_id);
    },
    "form.palet_id"(palet_id) {
      this.getRak(palet_id);
    },
    "form.rak_id"(rak_id) {
      this.getLaci(rak_id);
    },
    selectedInvoice(penerimaan_id) {
      const penerimaan =
        this.$store.getters["penerimaan-barang/getData"](penerimaan_id);
      if (!penerimaan) {
        this.displayError({
          message: "Data Penerimaan tidak ditemukan, harap pilih yang lain",
        });
        return false;
      }

      this.pb = penerimaan;
      this.form.penerimaan_id = this.pb.id;
      this.params = {
        penerimaan_id,
      };
      this.getBarangPenerimaan();
    },
  },
  data: () => ({
    params: {},
    loading: false,
    selectedInvoice: null,
    selectedBarang: null,
    addedItems: [],
    fieldsAddedItems: [
      { key: "no", label: "No" },
      { key: "barang", label: "Barang" },
      { key: "jumlah", label: "Jumlah" },
      { key: "lokasi", label: "Lokasi" },
      { key: "action", label: "#" },
    ],
    fields: [
      { key: "action", label: "#" },
      { key: "barang", label: "Barang" },
      { key: "sisa", label: "Sisa" },
      { key: "satuan", label: "Satuan" },
    ],
    bloks: [],
    pb: {
      id: null,
      no_invoice: null,
    },
    raks: [],
    lacis: [],
    palets: [],
    gudangs: [],
    barangs: [],
    form: {
      penerimaan_id: null,
      gudang_id: null,
      blok_id: null,
      palet_id: null,
      rak_id: null,
      laci_id: null,
    },
    invoiceList: [],
    id_satuan: 0,
    hasil: 0,
    nilai: 0,
  }),
  methods: {
    async getKonversi(barang_id) {
      const optkonversi = await this.$store.dispatch("konversi/getData", {
        id_barang: barang_id,
      });
      this.optkonversi = [];
      optkonversi.forEach((konv) => {
        this.optkonversi.push({
          value: konv.nilai,
          text: konv.satuan.satuan,
        });
      });
    },
    submit() {
      this.addedItems.map((item) => {
        delete item.barang;
        delete item.lokasi;
      });
      let payload = [];
      Object.assign(payload, this.addedItems);

      this.loading = true;
      this.$store
        .dispatch("penyimpanan/save", payload)
        .then(() => {
          this.loading = false;
          this.displaySuccess({
            message: "Stok Barang berhasil ditambahkan!",
          });

          setTimeout(() => {
            this.$router.push("/stock-in");
          }, 1000);
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    addBarang() {
      if (this.form.jumlah > this.selectedBarang.sisa) {
        this.$bvToast.toast(
          "Jumlah yang diisi tidak boleh lebih dari Sisa jumlah barang",
          {
            title: `Peringatan`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-right",
          }
        );
        // this.displayError({
        //     message: 'Jumlah yang disimpan harus kurang dari atau sama dengan sisa stok penerimaan'
        // })
        return false;
      }
      this.$refs.form.validate().then((success) => {
        if (success) {
          let data = {};
          Object.assign(data, this.form);
          data.barang =
            this.selectedBarang && this.selectedBarang.barang
              ? this.selectedBarang.barang.nama
              : null;
          data.barang_id =
            this.selectedBarang && this.selectedBarang.barang
              ? this.selectedBarang.barang.id
              : null;

          // get lokasi
          const blok = this.bloks.find((blok) => blok.value == data.blok_id);
          const palet = data.palet_id
            ? this.palets.find((palet) => palet.value == data.palet_id)
            : null;
          const rak = data.rak_id
            ? this.raks.find((rak) => rak.value == data.rak_id)
            : null;
          const laci = data.laci_id
            ? this.lacis.find((laci) => laci.value == data.laci_id)
            : null;

          let lokasi = blok ? `Blok: ${blok.text}` : "";
          if (palet) {
            lokasi += `, Palet: ${palet.text}`;
          }
          if (rak) {
            lokasi += `, Rak: ${rak.text}`;
          }

          if (laci) {
            lokasi += `, Laci: ${laci.text}`;
          }
          data.lokasi = lokasi;
          this.addedItems.push(data);
          (this.form.blok_id = null),
            (this.form.palet_id = null),
            (this.form.rak_id = null),
            (this.form.laci_id = null);
        }
      });
    },
    getGudang() {
      this.gudangs = [];
      this.$store.dispatch("gudang/getData", { jenis: 1 }).then((gudangs) => {
        gudangs.forEach((gudang) => {
          if (this.isAdminGudang) {
            const itsMyGudang = gudang.id == this.myGudang.id;
            if (itsMyGudang) {
              this.gudangs.push({
                value: gudang.id,
                text: gudang.nama_gudang,
              });
            }
          } else {
            this.gudangs.push({
              value: gudang.id,
              text: gudang.nama_gudang,
            });
          }
        });
      });
    },
    getBlok(gudang_id) {
      this.bloks = [];
      this.$store.dispatch("blok/getData", { gudang_id }).then((bloks) => {
        bloks.forEach((blok) => {
          this.bloks.push({
            value: blok.id,
            text: blok.blok,
          });
        });
      });
    },
    getpalet(blok_id) {
      this.palets = [];
      this.$store.dispatch("pallet/getData", { blok_id }).then((palets) => {
        palets.forEach((palet) => {
          this.palets.push({
            value: palet.id,
            text: palet.palet,
          });
        });
      });
    },
    getRak(palet_id) {
      this.raks = [];
      this.$store.dispatch("rak/getData", { palet_id }).then((raks) => {
        raks.forEach((rak) => {
          this.raks.push({
            value: rak.id,
            text: rak.rak,
          });
        });
      });
    },
    getLaci(rak_id) {
      this.lacis = [];
      this.$store.dispatch("laci/getData", { rak_id }).then((lacis) => {
        lacis.forEach((laci) => {
          this.lacis.push({
            value: laci.id,
            text: laci.laci,
          });
        });
      });
    },
    getBarangPenerimaan() {
      this.$store
        .dispatch("penerimaan-barang/getDataBarang", this.params)
        .then((barangs) => {
          this.barangs = barangs;
        })
        .catch((e) => this.displayError(e));
    },
    getInvoicePenerimaan() {
      let params = { where: { selesai: 0 } };
      if (this.isAdminGudang) {
        params.gudang_id = this.myGudang.id;
        this.form.gudang_id = this.myGudang.id;
      }
      this.loading = true;
      this.$store
        .dispatch("penerimaan-barang/getData", params)
        .then((penerimaanBarangs) => {
          this.loading = false;
          penerimaanBarangs.forEach((penerimaan) => {
            this.invoiceList.push({
              value: penerimaan.id,
              text: penerimaan.no_invoice,
            });
          });

          if (this.$route.query.inv) {
            this.selectedInvoice = this.$route.query.inv;
          }

          this.getGudang();
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
  },
  computed: {
    isInvoiceAvailable() {
      return this.$route.query.inv;
    },
  },
  created() {
    this.getInvoicePenerimaan();
  },
};
</script>